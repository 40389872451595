import React, { useState } from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Grid,
  GridItem,
  FormErrorMessage,
  Heading,
  Container,
  Alert,
  AlertTitle,
  AlertDescription,
  Image,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { getCsrfToken, signIn } from 'next-auth/react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import Meta from '@/components/Meta'
import { getAppRedirection } from '@/utils/appRedirection'
import { loginCredentials } from '@/debug'

const Login = () => {
  const { handleSubmit, router, register, errors, isSubmitting } = useLogin()
  return (
    <>
      <Meta title="Log In" />
      <Container maxWidth="container.xl" mt="12">
        <Grid templateColumns={{ md: 'repeat(12, 1fr)' }} gap="6">
          <GridItem
            colStart={{ md: '4', lg: '5' }}
            colSpan={{ md: '6', lg: '4' }}
          >
            <form onSubmit={handleSubmit}>
              <Grid gap="6">
                <GridItem display="flex" justifyContent="center">
                  <Image
                    src="/logo-web.png"
                    alt="Nursona Logo"
                    maxH="150px"
                    alignSelf="center"
                  />
                </GridItem>
                <GridItem>
                  <Heading as="h1" fontSize="3xl" mb="2">
                    Log In
                  </Heading>
                </GridItem>
                {router?.query?.error && (
                  <GridItem>
                    <Alert status="error" d="block">
                      <AlertTitle>Login Failed</AlertTitle>
                      <AlertDescription>
                        {router?.query?.error}
                      </AlertDescription>
                    </Alert>
                  </GridItem>
                )}
                <GridItem>
                  <FormControl id="email" isInvalid={errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="text"
                      {...register('email', { required: true })}
                      isRequired
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="password" isInvalid={errors.password}>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      {...register('password', { required: true })}
                      isRequired
                    />
                    <FormErrorMessage>
                      {errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    loadingText="Logging in..."
                    isFullWidth
                  >
                    Log In
                  </Button>
                </GridItem>
                <GridItem textAlign="center">
                  <NextLink href="/forgot-password" passHref>
                    <Button as={Link} colorScheme="blue" variant="link">
                      Forgot Password
                    </Button>
                  </NextLink>
                </GridItem>
              </Grid>
            </form>
          </GridItem>
        </Grid>
      </Container>
    </>
  )
}

export default Login

const useLogin = () => {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...loginCredentials },
  })

  const onSubmit = async (form) => {
    try {
      setIsSubmitting(true)
      getCsrfToken()
      await signIn('credentials', form)
    } catch (error) {
      setIsSubmitting(false)
      alert(error.message)
    }
  }
  return {
    router,
    isSubmitting,
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
  }
}

export const getServerSideProps = getAppRedirection
