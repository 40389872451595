import React from 'react'
import Head from 'next/head'

export default function Meta({
  title = '',
  description = '',
  image = '',
  url = '',
  children,
}) {
  const titlePattern = `${title} | Nursona Admin`
  const attrs = [
    {
      hid: 'title',
      name: 'title',
      content: titlePattern,
    },
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: titlePattern,
    },
    { hid: 'og:url', property: 'og:url', content: url },
    {
      hid: 'og:description',
      property: 'og:description',
      content: description,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: image,
    },
    {
      hid: 'twitter:title',
      property: 'twitter:title',
      content: titlePattern,
    },
    {
      hid: 'twitter:url',
      property: 'twitter:url',
      content: url,
    },
    {
      hid: 'twitter:description',
      property: 'twitter:description',
      content: description,
    },
    {
      hid: 'twitter:image',
      property: 'twitter:image',
      content: image,
    },
  ]

  return (
    <Head>
      <title>{titlePattern}</title>
      {attrs
        .filter((attr) => attr.content)
        .map((attr, idx) => (
          <meta key={idx} {...attr} />
        ))}
      {children}
    </Head>
  )
}
