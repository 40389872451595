export const applicationStatusType = Object.freeze({
  APPLIED: 'Applied',
  SUBMITTED: 'Submitted',
  OFFER: 'Offer',
  ACCEPTED: 'Accepted',
  ONBOARDING: 'Onboarding',
  HIRED: 'Hired',
  DECLINED: 'Declined',
  ARCHIVED: 'Archived',
})
export const credentialStatusType = Object.freeze({
  DENIED: 'denied',
  APPROVED: 'approved',
  PENDING: 'pending',
})

export const jobStatusType = Object.freeze({
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ENDED: 'ended',
})

export const jobType = Object.freeze({
  PER_DIEM: 'Per Diem',
  CONTRACT: 'Contract',
  DIRECT_HIRE: 'Direct Hire',
})

export const adminRole = Object.freeze({
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
})

export const userType = Object.freeze({
  ADMIN: 'admin',
  RECRUITER: 'recruiter',
  FACILITY: 'facility',
})

export const applicationDetailsGroup = Object.freeze({
  Personal: ['zip-code', 'resume-file'],
  Skills: ['specialty', 'discipline'],
  License: ['license-number', 'license-state'],
  References: ['reference-1', 'reference', 'reference-2'],
})

export const applicationDetailsLabel = Object.freeze({
  'zip-code': 'Zip Code',
  'resume-file': 'Resume',
  'license-number': 'License Number',
  'license-state': 'License State',
  'reference-1': 'Reference 1',
  'reference-2': 'Reference 2',
  reference: 'Reference',
  specialty: 'Specialty',
  discipline: 'Discipline',
})

export const applicationCredentialsType = Object.freeze({
  DETAIL: 'detail',
  CREDENTIAL: 'credential',
})

export const timesheetsStatusType = Object.freeze({
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
})

export const candidateStatusType = Object.freeze({
  NEW: {
    label: 'New',
    type: 'new',
  },
  ACTIVE: {
    label: 'Active',
    type: 'active',
  },
  COMPLETED: {
    label: 'Completed',
    type: 'completed',
  },
  SUBMISSION: {
    label: 'Submission',
    type: 'submission',
  },
  ONBOARDING: {
    label: 'Onboarding',
    type: 'onboarding',
  },
  PLACED: {
    label: 'Placed',
    type: 'placed',
  },
})
